import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui"; // 引入 Element UI
import "element-ui/lib/theme-chalk/index.css"; // 引入 Element UI 的样式
Vue.use(ElementUI); // 全局使用 Element UI
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
