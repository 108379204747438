<template>
  <div class="home">
    <img class="img" src="../assets/img2.png" />
    <div class="contentBox">
      <template v-if="showPageType == 1">
        <div class="topBox">
          <div class="headBox">
            <div class="text0" style="flex: 1">
              {{ text }}
            </div>
            <div style="color: #8b8b8b; margin-left: 25px">
              <i class="el-icon-warning-outline" @click="dialogVisibleTap"></i>
            </div>
          </div>
          <div class="centerContentBox" style="margin-top: 15px">
            <div class="text0" style="font-size: 15px">
              Do you have relevant investment experience?
            </div>
            <!-- <div class="headBox" style="margin-top: 15px">
              <div style="width: 30px">
                <img class="img" src="../assets/img1.png" />
              </div>
              <span class="text0" style="margin: 0px 12px 0px 8px">+1</span>
              <el-input
                placeholder="Enter your WhatsApp number"
                v-model="input"
                oninput="this.value=this.value.replace(/[^\d]/g,'')"
                clearable
                :maxlength="10"
              >
              </el-input>
            </div> -->
            <div class="yesBox" @click="showType = 2">
              <div class="choiceText">Yes</div>
              <div
                class="yesChoice"
                :style="`border: 1px solid ${
                  showType == 2 ? '#5edd60' : '#dadada'
                }`"
              >
                <img
                  v-if="showType == 2"
                  style="width: 100%; height: 100%"
                  src="../assets/yes.png"
                />
              </div>
            </div>
            <div class="yesBox" @click="showType = 3">
              <div class="choiceText">No,I am not interested in investing</div>
              <div
                class="yesChoice"
                :style="`border: 1px solid ${
                  showType == 3 ? '#5edd60' : '#dadada'
                }`"
              >
                <img
                  v-if="showType == 3"
                  style="width: 100%; height: 100%"
                  src="../assets/yes.png"
                />
              </div>
            </div>
            <div class="text0" style="margin-top: 20px">Privacy Notice</div>
            <span style="margin-top: 15px; color: #8b8b8b">
              By submitting this form, you agree to send your personal
              information to Ai, who will use it according to their policies.
              <span style="color: #333; font-weight: bold">
                View Ai's Privacy Policy.
              </span>
              TikTok will also save your information and use it in accordance
              with the
              <span style="color: #333; font-weight: bold" @click="jumpOther">
                TikTok Privacy Policy
              </span>
              , including for autofill purposes and to improve your ad
              experience. You can manage your autofill information in your
              account settings.
            </span>
          </div>
        </div>
        <div class="feetBox">
          <template v-if="showType != 1">
            <div class="buttonShow" style="cursor: pointer" @click="submitTap">
              Submit
            </div>
          </template>
          <template v-else>
            <div class="buttonShow" style="background: #f1f1f2; color: #b3bac1">
              Submit
            </div>
          </template>
        </div>
      </template>
      <template v-if="showPageType == 2">
        <div class="topBox">
          <div class="imgBox">
            <img class="imgStyle" src="../assets/yes.png" />
          </div>
          <div class="centerContentBox" style="color: #333">
            <div class="text0" style="margin-top: 30px; text-align: center">
              Are you ready to make $3,000 a day?
            </div>
            <span style="margin-top: 15px; text-align: center">
              By participating in the Trading Challenge you will receive:
            </span>
            <span style="text-align: center">
              1. The intelligent trading system provides you with the best way.
            </span>
            <span style="text-align: center">
              2.Every new member receives one-on-one service from our team of
              professionals.
            </span>
            <span style="text-align: center">
              3.Professional technical staff provide acurate trading strategies
              every day, which can achieve stable and considerable profits. lf
              you want to become better, if you want to live a better life, if
              you also have the dream of becoming rich, join now.
            </span>
          </div>
        </div>
        <div class="feetBox">
          <div class="buttonShow" style="cursor: pointer" @click="jumpOtherTwe">
            Wealth Links
          </div>
        </div>
      </template>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      top="20vh"
      :show-close="false"
      :center="true"
      width="75%"
    >
      <div class="showLoadBox">About your information</div>
      <div class="showLoadContent">
        The information you input via this ad will only be accessible by the
        advertiser. TikTok will only obtain the encrypted information and will
        not use the information collected for any other purposes. The pre-filled
        information you may find in the form comes from your TikTok profile. You
        may edit it at any time.
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data() {
    return {
      showType: 1, // 选项控制
      showPageType: 1, // 页面控制显示
      text: "The smart contract trading competition is in progress, click to participate now.",
      input: "",
      dialogVisible: false,
    };
  },
  methods: {
    dialogVisibleTap() {
      this.dialogVisible = true;
    },
    // 提交
    submitTap() {
      if (this.showType == 2) {
        this.showPageType = 2;
        return;
      } else if (this.showType == 3) {
        window.location.href = "facebook.com";
      }
    },
    // 跳转
    jumpOther() {
      window.location.href =
        "https://www.tiktok.com/legal/page/us/privacy-policy/en";
    },
    // 跳转
    jumpOtherTwe() {
      window.location.href =
        "https://www.tiktok.com/legal/page/us/privacy-policy/en";
    },
  },
};
</script>
<style>
.home {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  box-sizing: border-box;
}

.img {
  width: 100%;
  height: auto;
}

.contentBox {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
  margin-top: -15px;
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;
}

.topBox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;
}

.headBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text0 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.centerContentBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.el-input__inner {
  /* border: none !important; */
  border-radius: 0px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #575757 !important;
  padding: 0px 30px 0px 0px !important;
}

.feetBox {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px 10px 10px;
  border-top: 1px solid #adadad;
}

.buttonShow {
  width: 100%;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  background: #fe2c55;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.el-dialog__header {
  padding: 0px 20px 0px !important;
}

.el-button {
  cursor: default !important;
  border: none !important;
}

.el-dialog {
  border-radius: 8px !important;
}

.el-dialog__footer {
  border-top: 1px solid #dadada;
  padding: 10px !important;
}

.showLoadBox {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.showLoadContent {
  width: 100%;
  margin-top: 15px;
  color: #333;
  text-align: center;
}

.imgBox {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgStyle {
  width: 80px;
  height: 80px;
}

.yesBox {
  width: 100%;
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
}

.choiceText {
  color: #333;
  font-size: 15px;
}

.yesChoice {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
